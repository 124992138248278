import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import shipService from '@/http/requests/ship/shipService'
import Pager from '@core/dev-extreme/utils/pager'
import { v4 as uuidv4 } from 'uuid'

const store = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: loadOptions => {
    const options = {}
    const sort = (loadOptions.sort && loadOptions.sort.length > 0) ? loadOptions.sort : null
    const pager = new Pager(options)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    // let sortOrder = ''
    if (sort) {
      pager.setSortOrder(sort)
      // sortOrder = `&sort=${pager.toCreateSortOrder()}`
    }

    // const pageableQuery = `${pager.toCreatePageable()}${sortOrder}`
    const pagebleString = pager.toCreatePageable()
    const filters = (loadOptions.searchValue) ? loadOptions.searchValue : null
    return shipService
      .findAmazonRanks(filters, pagebleString)
      .then(response => {
        const data = response.data
        return {
          data: data.content.map(el => ({
            ...el,
            id: uuidv4(),
          })),
          totalCount: data.totalElements,
        }
      })
  },
})

const dataSource = new DataSource({
  store: store,
})

export {
  dataSource as AmazonRanksDataSource,
}
