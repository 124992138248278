<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0">
        <dx-data-grid
          id="amazonRanksDataGrid"
          ref="amazonRanksDataGridRef"
          :height="setHeight"
          :data-source="datasource"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="true"
          :customize-columns="customizeColumns"
          @option-changed="onOptionChanged"
          @initialized="onInitialized"
          @content-ready="onContentReady"
          @cell-dbl-click="onCellDblClick"
          @toolbar-preparing="onToolbarPreparing($event)"
          @editor-preparing="onEditorPreparing"
        >
          <!--region    ::DataGird base components -->
          <dx-sorting mode="single" />
          <dx-remote-operations :paging="true" :filtering="false" :sorting="true" :summary="true" />
          <dx-column-chooser :enabled="true" mode="select" title="Column Chooser" />
          <dx-search-panel
            :highlight-search-text="true"
            :visible="true"
            :width="200"
            placeholder="Search..."
          />
          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
          <dx-selection mode="multiple" show-check-boxes-mode="always"
            select-all-mode="page" :allow-select-all="true"
            :width="10"
            />
          <!--endregion ::DataGrid base components -->
          <dx-column data-field="smallImage" cell-template="imageCellTemplate" :width="100" caption="Image" alignment="center" :allow-sorting="false" />
          <template #imageCellTemplate="{data}">
            <div>
              <img class="rounded img-fluid img-thumbnail" :src="data.value || defaultImageUrl" style="height:72px;width:72px;">
            </div>
          </template>
          <dx-column data-field="title" caption="Title" cell-template="titleCellTemplate" :min-width="240" :allow-sorting="false" />
          <dx-column data-field="upc" caption="UPC" :width="120" :allow-sorting="false" />
          <dx-column data-field="asin" caption="ASIN" :width="120" cell-template="asinCellTemplate" :allow-sorting="false" />
          <dx-column data-field="supplier" caption="Supplier" :width="250" cell-template="titleCellTemplate" :allow-sorting="false" />
          <dx-column data-field="amzRank" :width="100" caption="Ranking" :allow-sorting="false" />
          <dx-column data-field="quantity" caption="Quantity" :width="100" :allow-sorting="false" />
          <dx-column data-field="itemCost" caption="Cost" :width="100" cell-template="currencyTemplate" :allow-sorting="false" />
          <dx-column data-field="salePrice" caption="Sale Price" :width="100" cell-template="currencyTemplate" :allow-sorting="false" />
          <template #currencyTemplate="{ data }">
              {{ getCurrencyFormat(data) }}
            </template>
          <template #titleCellTemplate="{data}">
            <div class="dx-product-title">
              {{ data.value }}
            </div>
          </template>
          <template #asinCellTemplate="{data}">
            <a target="blank" :href="`https://www.amazon.com/gp/product/${data.value}`">
              {{ data.value }}
            </a>
          </template>
          <template #toolbarFilters>
            <div class="d-flex">
              <div class="mr-1">
                <dx-util-select-box
                  v-model="selectedDateRange"
                  :data-source="dateRangesList"
                  :drop-down-options="{width: '180px'}"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="setDateRanges"
                />
              </div>
              <div v-show="isCustomDateRange" class="col px-half">
                <dx-util-date-box
                  v-model="filters.beginDate"
                  :max="filters.endDate"
                  type="date"
                  @value-changed="searchByFilter"
                />
              </div>
              <div v-show="isCustomDateRange" class="col px-half">
                <dx-util-date-box
                  v-model="filters.endDate"
                  :min="filters.beginDate"
                  type="date"
                  @value-changed="searchByFilter"
                />
              </div>
            </div>
          </template>
        </dx-data-grid>
      </div>
      <div class="col-12 px-0">
        <light-box
          :items="images"
          :index="index"
          :effect="'fade'"
          @close="index = null"
        />
      </div>
    </div>
    <div class="col-md-12 py-1 position-absolute fixed-bottom">
      <div class="d-flex flex-row justify-content-start align-items-center">
        <p-icon name="bi-info-circle-fill" color="warning" size="32px" class="mr-1" />
        <span><strong>Beta Version: This is a beta version of this report module. It is currently being tested and its usage is for free. Some features may be limited upon the full version release and will be subject to additional charges.</strong></span>
      </div>
    </div>
  </div>
</template>

<script>
import Pager from '@core/dev-extreme/utils/pager'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import useDateRanges from '@/@core/composables/useDateRanges.js'
import { Notify } from '@robustshell/utils/index'
import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import predefinedDateRangesEnum, { getPredefinedDateRangesList } from '@/enums/predefinedDateRanges.enum.js'
import Filters from '@robustshell/mixins/filters'
import { currencyFormatter } from '@core/utils/filter'
import shipService from '@/http/requests/ship/shipService'
import CoolLightBox from 'vue-cool-lightbox'
import { AmazonRanksDataSource } from './amazonRanksStore'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

export default {
  components: {
    'light-box': CoolLightBox,
  },
  mixins: [GridBase, Filters],
  data() {
    const firstDayOfMonth = new Date()
    const plusOneDay = new Date()
    firstDayOfMonth.setDate(1)
    firstDayOfMonth.setHours(0, 0, 0, 1)
    plusOneDay.setDate(plusOneDay.getDate() + 1)

    return {
      datasource: AmazonRanksDataSource,
      isVisible: false,
      selectedProductId: null,
      filters: {
        q: '',
        beginDate: firstDayOfMonth,
        endDate: plusOneDay,
      },
      selectedDateRange: predefinedDateRangesEnum.CURRENTMONTH.value,
      dateRangesList: getPredefinedDateRangesList(),
      defaultImageUrl,
      images: [],
      index: null,

    }
  },
  setup() {
    const { setDateRanges, startDate, isCustomDateRange } = useDateRanges()
    return {
      setDateRanges,
      startDate,
      isCustomDateRange,
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    dataGrid() {
      return this.$refs.amazonRanksDataGridRef.instance
    },
  },
  watch: {
    startDate: {
      immediate: true,
      handler(newValue) {
        const plusOneDay = new Date()
        plusOneDay.setDate(plusOneDay.getDate() + 1)
        this.filters.beginDate = newValue || this.filters.beginDate
        this.searchByFilter()
      },
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    getCurrencyFormat(data) {
      return currencyFormatter(data.value)
    },
    onOptionChanged(e) {
      if (e.fullName === 'paging.pageSize') {
        AmazonRanksDataSource.pageIndex(0)
        AmazonRanksDataSource.reload()
      }
    },
    onEditorPreparing(e) {
      if (e.parentType !== 'searchPanel') return
      if (e.parentType === 'searchPanel') {
        e.editorOptions.onValueChanged = arg => {
          const query = arg.component.option('value')
          if (!query) {
            this.filters.q = query.trim()
            e.component.searchByText('')
            this.searchByFilter()
          }
        }
        e.editorOptions.onKeyDown = arg => {
          if (arg.event.keyCode === 13) {
            let query = arg.component.option('value')
            query = query.trim()
            arg.component.option('value', query)
            this.filters.q = query
            this.searchByFilter()
            e.component.searchByText(query)
          }
          if (arg.event.keyCode === 27) {
            e.component.searchByText('')
            arg.component.option('value', '')
            this.filters.q = ''
            this.searchByFilter()
          }
        }
      }
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)

      toolbarItems.unshift({
        location: 'before',
        template: 'toolbarFilters',
      })
      toolbarItems.push({
        widget: 'dxDropDownButton',
        options: {
          keyExpr: 'id',
          displayExpr: 'name',
          dropDownOptions: {
            width: 240,
          },
          text: 'Export',
          icon: 'exportxlsx',
          items: [
            {
              id: 'all',
              name: 'All Items',
              icon: 'selectall',
            },
            {
              id: 'selected',
              name: 'Selected Items',
              icon: 'exportselected',
            },
          ],
          width: 120,
          type: 'default',
          stylingMode: 'contained',
          hint: 'Download Shipment Items',
          onItemClick: arg => {
            switch (arg.itemData.id) {
              case 'selected':
                this.includeDetails = false
                this.onDownloadSelectedItems()
                break
              default:
                this.includeDetails = false
                this.onDownloadItems()
                break
            }
          },
        },
        location: 'after',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.resetFilters()
            this.searchByFilter()
          },
        },
        location: 'after',
      })
    },
    resetFilters() {
      this.filters.q = ''
      const firstDayOfMonth = new Date()
      const plusOneDay = new Date()
      firstDayOfMonth.setDate(1)
      firstDayOfMonth.setHours(0, 0, 0, 1)
      plusOneDay.setDate(plusOneDay.getDate() + 1)
      this.filters.beginDate = firstDayOfMonth
      this.filters.endDate = plusOneDay
      this.selectedDateRange = predefinedDateRangesEnum.CURRENTMONTH.value
    },
    loadData() {
      this.resetFilters()
      AmazonRanksDataSource.searchValue(this.filters)
      AmazonRanksDataSource.load()
    },
    searchByFilter() {
      AmazonRanksDataSource.searchValue(this.filters)
      AmazonRanksDataSource.reload()
    },
    async onDownloadItems(e) {
      const pager = new Pager({})
      const max = 2000
      pager.setPageNumber(0, max)
      const pageableQuery = `${pager.toCreatePageable()}`
      const response = await shipService.findAmazonRanks(this.filters, pageableQuery)
      const data = response.data.content
      if (data === undefined || data === null || data?.length <= 0) {
        Notify.warning('There are not any items to download.')
        return
      }
      this.onDownloadExportData(data)
    },
    onDownloadSelectedItems() {
      const data = this.dataGrid.getSelectedRowsData()
      if (Array.isArray(data) && data.length > 0) {
        this.onDownloadExportData(data)
      } else {
        Notify.warning('Please select at least one item to export data')
      }
    },
    onDownloadExportData(data) {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Amazon Rankings')
      worksheet.columns = [
        { header: 'Title', key: 'title', width: 18 },
        { header: 'UPC', key: 'upc', width: 18 },
        { header: 'ASIN', key: 'asin', width: 12 },
        { header: 'Ranking', key: 'ranking', width: 12 },
        { header: 'Cost', key: 'cost', width: 12 },
        { header: 'Sale Price', key: 'salePrice', width: 12 },
        { header: 'Quantity', key: 'quantity', width: 12 },
        { header: 'Supplier', key: 'supplier', width: 12 },
      ]

      worksheet.getRow(0).font = {
        size: 16,
        bold: true,
      }
      worksheet.getRow(0).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getRow(0).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D59C9C' },
      }
      worksheet.autoFilter = 'A1:AB1'
      worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }]

      data.forEach(item => {
        worksheet.addRow([
          item.title,
          item.upc,
          item.asin,
          item.ranking,
          item.cost,
          item.salePrice,
          item.quantity,
          item.supplier,
        ])
      })

      workbook.xlsx.writeBuffer().then(buffer => {
        const fileName = Date.now()
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `AmazonRanks-${fileName}.xlsx`,
        )
      })
    },
    onCellDblClick(e) {
      if (e.rowType === 'data' && e.column.dataField === 'smallImage') {
        if (e.eventType === 'dxdblclick') {
          if (e.data.largeImage) {
            this.images.length = 0
            this.index = 0
            this.images.push(e.data.largeImage)
          }
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
